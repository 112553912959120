import { Grid } from '@mui/material';
import { useLocale } from 'next-intl';

import ProductCard from '@/ui/components/Card/forGrid/ImageCard';
import { urlBuilder } from '@/utils/urlBuilder';

interface ProcuctsListProps {
  products: Product[];
}

/**
 * @todo TransitionGroup
 */
const ProductsList = ({ products }: ProcuctsListProps) => {
  const locale = useLocale();

  return (
    <Grid container spacing={4}>
      {products.map(product => {
        const augmentedProduct = {
          ...product,
          linkUrl: urlBuilder({ item: product, locale }),
        };

        return <ProductCard key={product.id} item={augmentedProduct} />;
      })}
    </Grid>
  );
};

export default ProductsList;
