'use client';

import React from 'react';

import ProductsList from './ProductsList';
import ShowMoreButton from './ShowMoreButton';
import { getChildProducts } from './utils';
import { useBreakpoint } from '@/hooks/useBreakpoint';
// import DocumentSeoHeader from '@/ui/components/DocumentSeoHeader';
import { log } from '@/utils/logger';

const ITEMS_PER_ROW = 6;

// number of items for individual breakpoints
const ITEMS_TO_DISPLAY = {
  xs: ITEMS_PER_ROW,
  sm: ITEMS_PER_ROW,
  md: ITEMS_PER_ROW,
  lg: ITEMS_PER_ROW * 2,
  xl: ITEMS_PER_ROW * 2,
};

const ProductsSections = ({ products }) => {
  const [showAllProducts, setShowAllProducts] = React.useState(false);

  const breakpoint = useBreakpoint();

  const childrenProducts: Product[] = React.useMemo(() => {
    return getChildProducts(products);
  }, [products]);

  const displayedProducts = React.useMemo(() => {
    const homepageProducts = childrenProducts.filter(
      product => product?.meta?.hp_visibility === true
    );

    const orderedProducts = homepageProducts.sort(
      (a, b) => a?.order - b?.order
    );

    if (showAllProducts) {
      return orderedProducts;
    }

    return orderedProducts?.slice(0, ITEMS_TO_DISPLAY[breakpoint]);
  }, [childrenProducts, showAllProducts, breakpoint]);

  const toggleShowAllState = React.useCallback(
    () => setShowAllProducts(!showAllProducts),
    [showAllProducts]
  );

  if (!products) {
    log.error('No products found in the response');
    return null;
  }

  return (
    <>
      <ProductsList products={displayedProducts} />
      <ShowMoreButton
        onClickAction={toggleShowAllState}
        showAllProducts={showAllProducts}
      />
    </>
  );
};

export default ProductsSections;
