'use client';
import { useTranslations } from 'next-intl';

import Button from '@/ui/components/Button';

const ShowMoreButton = ({ showAllProducts, onClickAction }) => {
  const translate = useTranslations();

  return (
    <Button
      variant="outlined"
      label={
        showAllProducts
          ? translate('homepage-products-showLess')
          : translate('homepage-products-showMore')
      }
      onClick={onClickAction}
    />
  );
};

export default ShowMoreButton;
