/**
 * @description there are two levels of products: family and children
 * @todo async not supported for client components as of nextjs 14.0.0
 */
export const getChildProducts = (products: Product[]): Product[] => {
  const accumulator: Product[] = [];

  const extractedChildren = products?.reduce((acc, product) => {
    const children = product.childrens; // typo! im good ingrysh
    if (children && children.length > 0) {
      children.forEach(child => {
        acc.push(child);
      });
    }
    return acc;
  }, accumulator);

  return extractedChildren;
};
